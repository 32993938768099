import React from "react";
import Section from "../../components/Section";

const Skills = () => {
  return (
    <Section id="skills" title={`Skills & Stacks`} borderColor="blue">
      <div className="text-center textShadow">
        <a
          href="https://stackshare.io/rickvian/rickvian-aldi-stack"
          target="_blank"
        >
          Click to see details
        </a>
      </div>
    </Section>
  );
};

export default Skills;
