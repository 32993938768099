import React, { Component } from "react";
import Helmet from "react-helmet";
// import { Link } from "gatsby";

// CSS
import "../styles/homepage.scss";

// Components
import Layout from "../components/layout";
import ScrolldownAnimated from "../components/scrolldownAnimated";
import RickvianPicture from "../../static/rickvian-6bit.png";
import Projects from "./Homepage/Projects";
import Introductions from "./Homepage/Introductions";
import WorkExperience from "./Homepage/WorkExperience";
import Skills from "./Homepage/Skills";

import Connect from "./Homepage/Connect";

// Animations
import lax from "lax.js";
import GradientCanvas from "../components/GradientCanvas";

class IndexPage extends Component {
  componentDidMount() {
    // Setup lax
    lax.init();

    lax.addDriver("scrollY", function () {
      return window.scrollY;
    });

    lax.addElements(
      ".scrollIconParallax",
      {
        scrollY: {
          opacity: [
            [0, 200],
            [1, 0],
          ],
        },
      },
      []
    );

    lax.addElements(
      ".profilePicParallax",
      {
        scrollY: {
          translateY: [
            [0, 200],
            [1, "(screenHeight / 2) - 40"],
          ],
          translateX: [
            [0, 200],
            {
              768: [1, "(screenWidth / 2) - 40"],
              769: [1, "(screenWidth / 2) - 110 "],
            },
          ],
          scale: [
            [0, 200],
            [1, 0.5],
          ],
        },
      },
      []
    );

    lax.addElements(
      ".blurScrollIn",
      {
        scrollY: {
          blur: [
            ["elInY", "elCenterY - 200"],
            [100, 0],
          ],
          // scale: [
          //     [0, 200],
          //     [1, 0.5]
          // ]
        },
      },
      []
    );

    lax.addElements(
      ".backgroundHueShift",
      {
        scrollY: {
          filter: [
            [0, "screenHeight"],
            [100, 0],
            {
              cssFn: (val) => {
                return `grayscale(${val}%)`;
              },
            },
          ],
        },
      },
      []
    );

    if (window.location.hash) {
      window.scrollTo({
        top: window.location.hash,
        behavior: "smooth", // smooth scroll.
      });
    } else {
      console.log("no hash detected");
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <script
            async
            src="https://cdn1.stackshare.io/javascripts/client-code.js"
            charset="utf-8"
          ></script>
          <meta charSet="utf-8" />
          <title>Rickvian Aldi</title>
          <meta
            name="description"
            content="Software Engineer - Web Front End"
          />
          <link rel="canonical" href="http://www.rickvianaldi.com" />
        </Helmet>

        <GradientCanvas />

        {/* <div className="background-sea"></div> */}

        <main className="main-container lazy-background">
          <div
            className="profile-picture profilePicParallax"
            onClick={this.scrollToTop}
          >
            <img src={RickvianPicture} alt="Rickvian icon" />
          </div>

          <section
            className={`fullsection flex-direction-column section-profile-picture`}
          >
            <div>{/* Empty div to retain balance  */}</div>

            <div className="scrollIconParallax">
              <ScrolldownAnimated />
            </div>
          </section>

          <section
            id="introduction"
            className={`fullsection justify-center blurScrollIn`}
          >
            <Introductions />
          </section>

          <WorkExperience />

          <Skills />

          <Projects />

          <Connect />
        </main>
      </Layout>
    );
  }
}

export default IndexPage;
