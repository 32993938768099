import React from "react";
import Section from "../../../components/Section";
import ExperienceItem from "./ExperienceItem";

const experienceData = [
  {
    title: "NINJA VAN",
    link: "https://www.ninjavan.co",
    roles: [
      { years: "2021 - Present", position: "Software Engineer FE" },
    ],
  },
  {
    title: "UNIPIN.COM",
    link: "https://www.unipin.com",
    roles: [
      { years: "2018 - 2021", position: "Software Engineer (Front-End)" },
      { years: "2019 - 2020", position: "AVP. Digital Marketing" },
    ],
  },
  {
    title: "EDUMOR.COM",
    roles: [
      { years: "2016 - 2020", position: "Director & Front End Webdev" },
    ],
  },
  {
    title: "KREAVI.COM",
    link: "https://www.kreavi.com",
    roles: [
      { years: "2014 - 2015", position: "Web Developer Intern" }
    ],
  },
  {
    title: "ISTB (INSTITUTE)",
    link: "https://www.behance.net/gallery/22184767/STMIK-ISTB-web-design",
    roles: [
      {
        years: "2013 - 2014",
        position: "Creative Designer & Web Developer",
      },
    ],
  },
];

const WorkExperience = () => {
  return (
    <Section id="workexperience" borderColor="orange" title="Work Experience">
      <div className="box-inner-transparent textShadow">
        {experienceData.map((experience, index) => (
          <ExperienceItem
            key={index}
            title={experience.title}
            link={experience.link}
            roles={experience.roles}
          />
        ))}
      </div>
    </Section>
  );
};

export default WorkExperience;
